
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import dialog from "@/vuex/dialog";
  import mixinEmployee from "@/mixins/mixinEmployee";
  import * as util from "@/util";
  import UtilPopup from "@/components/util/Popup.vue";
  import CommonText from "@/components/common/Text.vue";
  import CommonSelect from "@/components/common/Select.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      UtilPopup,
      CommonText,
      CommonSelect,
      CommonButton,
    },
  })
  export default class Main extends Mixins(mixinEmployee) {
    get is_display() {
      return this.m.is_open_join_mail_nencho_dialog;
    }
    set is_display(val: boolean) {
      this.m.setIsOpenJoinMailNenchoDialog(val);
    }

    created() {
      this.tmp_employees = [
        {
          type: 5,
          name: null,
          kana: null,
          mail: null,
          code: null,
          hiring_type: 0,
          branch_id: this.s_branch_id,
        },
      ];
    }

    get tmp_employees() {
      return this.m.join_mail_employees;
    }
    set tmp_employees(val) {
      this.m.setJoinMailEmployees(val);
    }

    create_employee() {
      Vue.set(this.tmp_employees, this.tmp_employees.length, {
        type: 5,
        name: null,
        kana: null,
        mail: null,
        code: null,
        hiring_type: 0,
        branch_id: this.s_branch_id,
      });
    }
    delete_employee(index) {
      if (this.tmp_employees.length == 1) {
        dialog.openErrorDialog({ error: ["全ての行を削除することはできません。"] });
      } else {
        dialog.openForceConfirmDialog({
          msg: `${index + 1}行目を削除してよろしいですか？`,
          cancel_button: "削除しない",
          exec_button: "削除する",
          func: this.delete_employee_process,
          args: { index },
        });
      }
    }
    delete_employee_process({ index }) {
      this.tmp_employees.splice(index, 1);
    }

    get use_branch_hash() {
      const branch_hash = util.deep_copy(this.branch_hash);
      delete branch_hash[0];
      return branch_hash;
    }

    vue_set(i, key, val) {
      Vue.set(this.tmp_employees[i], key, val);
    }
    change_plan() {
      this.change_page("OrderChangePlan");
    }

    create_join_mail_employee() {
      if (this.header.plan == 0 && this.global.employee_num + this.tmp_employees.length > 10) {
        dialog.openForceConfirmDialog({
          title: "従業員数が上限に達してしまいます",
          msg: "現在ご利用中のプランAでは11人以上の従業員を登録することはできません。<br>BプランかCプランへ変更のうえ、再度社員登録を行ってください。",
          cancel_button: "キャンセル",
          exec_button: "プラン変更",
          func: this.change_plan,
          args: {},
        });
      } else {
        this.m.createEmployeeMail();
      }
    }
  }
