
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import utilMixins from '@/mixins';
import dialog from "@/vuex/dialog";
import mixinNencho from '@/mixins/mixinNencho';
import NenchoManagerNonencho from "@/components/nencho/manager/Nonencho.vue";
import NenchoManagerMonthchanger from "@/components/nencho/manager/Monthchanger.vue";
import NenchoManagerMain from "@/components/nencho/manager/Main.vue";

@Component({
  components: {
    NenchoManagerNonencho,
    NenchoManagerMonthchanger,
    NenchoManagerMain,
  }
})
export default class Nencho extends Mixins(mixinNencho) {

}
