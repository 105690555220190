import { Component, Vue, Mixins } from 'vue-property-decorator';
import global from "@/vuex/employee/global";
import now from "@/vuex/employee/now";
import employeely from "@/vuex/employee_each/employeely";
import order from "@/vuex/employee/order";
import join from "@/vuex/employee/join";
import retire from "@/vuex/employee/retire";
import * as util from '@/util';
import utilMixins from '@/mixins';
import manager from '@/vuex/employee/manager';

@Component
export default class mixinEmployee extends Mixins(utilMixins) {
  get is_set_global(): boolean {
    return global.is_set;
  }
  get is_set_now(): boolean {
    return now.data[this.viewing_branch] && now.data[this.viewing_branch].is_set;
  }
  get is_set_order(): boolean {
    return order.data[this.viewing_branch] && order.data[this.viewing_branch].is_set;
  }
  get is_set_join(): boolean {
    return join.data[this.viewing_branch] && join.data[this.viewing_branch].is_set;
  }
  get is_set_retire(): boolean {
    return retire.data[this.viewing_branch] && retire.data[this.viewing_branch].is_set;
  }

  get is_valid_global(): boolean {
    return global.is_valid;
  }
  get is_valid_now(): boolean {
    return now.data[this.viewing_branch] && now.data[this.viewing_branch].is_valid;
  }
  get is_valid_order(): boolean {
    return order.data[this.viewing_branch] && order.data[this.viewing_branch].is_valid;
  }
  get is_valid_join(): boolean {
    return join.data[this.viewing_branch] && join.data[this.viewing_branch].is_valid;
  }
  get is_valid_retire(): boolean {
    return retire.data[this.viewing_branch] && retire.data[this.viewing_branch].is_valid;
  }

  async get_global() {
    await global.fetch({auth: this.s_auth});
  }
  async get_now() {
    await now.fetch({branch_id: this.viewing_branch});
  }
  async get_order() {
    await order.fetch({branch_id: this.viewing_branch});
  }
  async get_join() {
    await join.fetch({branch_id: this.viewing_branch});
  }
  async get_retire() {
    await retire.fetch({branch_id: this.viewing_branch});
  }

  get global() {
    if (!this.is_set_global || !this.is_valid_global) this.get_global();
    if (this.is_set_global) 
      return global.data;
  }
  get now() {
    if (!this.is_set_now || !this.is_valid_now) this.get_now();
    if (this.is_set_now)
      return now.data[this.viewing_branch];
  }
  get order() {
    if (!this.is_set_order || !this.is_valid_order) this.get_order();
    if (this.is_set_order)
      return order.data[this.viewing_branch];
  }
  get join() {
    if (!this.is_set_join || !this.is_valid_join) this.get_join();
    if (this.is_set_join)
      return join.data[this.viewing_branch];
  }
  get retire() {
    if (!this.is_set_retire || !this.is_valid_retire) this.get_retire();
    if (this.is_set_retire)
      return retire.data[this.viewing_branch];
  }

  get viewing_branch() {
    var branch_id = this.query.viewing_branch_employee;
    if (branch_id === undefined) {
      if (this.s_auth == 2) branch_id = '0';
      else branch_id = Number(util.getLocalStorage('branch_id')); 
    }
    if (branch_id != 0 && this.s_auth == 2 && !this.is_multi_branch) {
      branch_id = '0';
    }
    this.query = {viewing_branch_employee: branch_id};
    return Number(branch_id);
  }
  set viewing_branch(val) {
    this.page = 1;
    this.query = {viewing_branch_employee: val};
  }


  get tab() {
    var tab = this.query.employee_tab;
    if (tab === undefined) {
      tab = 'now';
      this.query = {employee_tab: tab};
    }
    return tab;
  }
  set tab(val) {
    this.query_push = {employee_tab: val};
  }
  
  get m() {
    return manager;
  }

  get employees() {
    if (this.tab == 'now')
      return this.now.employees;
    else if (this.tab == 'order')
      return this.order.employees;
    else if (this.tab == 'join')
      return this.join.employees;
    else if (this.tab == 'retire')
      return this.retire.employees;
  }
  get branches() {
    return this.global.branches;
  }

  get employee_hash() {
    if (this.tab == 'now')
      return this.now.employee_hash;
    else if (this.tab == 'order')
      return this.order.employee_hash;
    else if (this.tab == 'join')
      return this.join.employee_hash;
    else if (this.tab == 'retire')
      return this.retire.employee_hash;
  }
  get branch_hash() {
    return this.global.branch_hash;
  }
  get branch_hash_all() {
    return this.global.branch_hash_all;
  }

  get orders() {
    if (this.tab == 'order')
      return this.order.orders;
  }



  get search_key() {
    var search_key = this.query.employee_search_key;
    if (search_key === undefined || search_key == '') {
      this.query = {employee_search_key: undefined};
      return null;
    } else {
      return search_key;
    }
  }
  set search_key(val) {
    this.page = 1;
    if (val == '' || val == null) {
      this.query = {employee_search_key: undefined};
    } else {
      this.query = {employee_search_key: val};
    }
  }


  get sort_date() {
    return this.query.employee_sort_date;
  }
  set sort_date(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_date: undefined};
    } else {
      this.query = {employee_sort_date: val};
    }
  }
  get sort_code() {
    return this.query.employee_sort_code;
  }
  set sort_code(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_code: undefined};
    } else {
      this.query = {employee_sort_code: val};
    }
  }
  get sort_name() {
    return this.query.employee_sort_name;
  }
  set sort_name(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_name: undefined};
    } else {
      this.query = {employee_sort_name: val};
    }
  }
  get sort_hiring_type() {
    return this.query.employee_sort_hiring_type;
  }
  set sort_hiring_type(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_hiring_type: undefined};
    } else {
      this.query = {employee_sort_hiring_type: val};
    }
  }
  get sort_branch_id() {
    return this.query.employee_sort_branch_id;
  }
  set sort_branch_id(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_branch_id: undefined};
    } else {
      this.query = {employee_sort_branch_id: val};
    }
  }
  get sort_kind() {
    return this.query.employee_sort_kind;
  }
  set sort_kind(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_kind: undefined};
    } else {
      this.query = {employee_sort_kind: val};
    }
  }
  get sort_status() {
    return this.query.employee_sort_status;
  }
  set sort_status(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_status: undefined};
    } else {
      this.query = {employee_sort_status: val};
    }
  }
  get sort_type() {
    return this.query.employee_sort_type;
  }
  set sort_type(val) {
    if (val == '' || val == null) {
      this.query = {employee_sort_type: undefined};
    } else {
      this.query = {employee_sort_type: val};
    }
  }

  get page() {
    var page = this.query.employee_page;
    if (page === undefined || page == '') {
      this.query = {employee_page: 1};;
      page = 1;
    }
    return page;
  }
  set page(val) {
    if (val == '' || val == null) {
      this.query = {employee_page: undefined};
    } else {
      this.query = {employee_page: val};
    }
  }

  get searched_sorted_orders() {
    var orders = util.deep_copy(this.orders);
    orders = this.search_orders(orders);
    orders = this.sort_orders(orders);
    return orders;
  }
  get managed_orders() {
    var orders = util.deep_copy(this.searched_sorted_orders);
    orders = this.page_orders(orders);
    return orders;
  }
  search_orders(orders) {
    if (this.search_key　=== null) {
      return orders;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter(str => str != '');
      var _this = this;
      return orders.filter(order => {
        var flag = 0;
        search_keys.forEach(key => {
          if (this.employee_hash[order.data.employee_id].name.toLowerCase().indexOf(key.toLowerCase()) != -1 || util.search_kana(this.employee_hash[order.data.employee_id].kana, key) || (this.employee_hash[order.data.employee_id].code && this.employee_hash[order.data.employee_id].code.toLowerCase().indexOf(key.toLowerCase()) != -1)) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_orders(orders) {
    if (this.sort_date)
      orders.sort((x, y) => util.sort_idx(x.date, y.date, this.sort_date));
    else if (this.sort_code)
      orders.sort((x, y) => util.sort_idx(this.employee_hash[x.data.employee_id] === undefined ? 0 : this.employee_hash[x.data.employee_id].code, this.employee_hash[y.data.employee_id] === undefined ? 0 : this.employee_hash[y.data.employee_id].code, this.sort_code));
    else if (this.sort_name)
      orders.sort((x, y) => util.sort_idx(this.employee_hash[x.data.employee_id] === undefined ? 0 : this.employee_hash[x.data.employee_id].kana, this.employee_hash[y.data.employee_id] === undefined ? 0 : this.employee_hash[y.data.employee_id].kana, this.sort_name));
    else if (this.sort_hiring_type)
      orders.sort((x, y) => util.sort_idx(this.employee_hash[x.data.employee_id] === undefined ? 0 : this.employee_hash[x.data.employee_id].hiring_type, this.employee_hash[y.data.employee_id] === undefined ? 0 : this.employee_hash[y.data.employee_id].hiring_type, this.sort_hiring_type));
    else if (this.sort_branch_id)
      orders.sort((x, y) => util.sort_idx(this.employee_hash[x.data.employee_id] === undefined ? 0 : this.employee_hash[x.data.employee_id].branch_id, this.employee_hash[y.data.employee_id] === undefined ? 0 : this.employee_hash[y.data.employee_id].branch_id, this.sort_branch_id));
    else if (this.sort_kind)
      orders.sort((x, y) => util.sort_idx(x.kind, y.kind, this.sort_kind));
    else if (this.sort_status)
      orders.sort((x, y) => util.sort_idx(x.data.status, y.data.status, this.sort_status));
    return orders;
  }
  page_orders(orders) {
    return orders.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }



  get searched_sorted_employees() {
    var employees = util.deep_copy(this.employees);
    employees = this.search_employees(employees);
    employees = this.sort_employees(employees);
    return employees;
  }
  get managed_employees() {
    var employees = util.deep_copy(this.searched_sorted_employees);
    employees = this.page_employees(employees);
    return employees;
  }
  search_employees(employees) {
    if (this.search_key　=== null) {
      return employees;
    } else {
      const search_keys = this.search_key.split(/\s+/).filter(str => str != '');
      console.log(search_keys);
      var _this = this;
      return employees.filter(employee => {
        var flag = 0;
        search_keys.forEach(key => {
          if (employee.name.toLowerCase().indexOf(key.toLowerCase()) != -1 || util.search_kana(employee.kana, key) || (employee.code && employee.code.toLowerCase().indexOf(key.toLowerCase()) != -1)) {
            flag = 1;
            return;
          }
        });
        return flag;
      });
    }
  }
  sort_employees(employees) {
    if (this.sort_code)
      employees.sort((x, y) => util.sort_idx(x.code, y.code, this.sort_code));
    else if (this.sort_name)
      employees.sort((x, y) => util.sort_idx(x.kana, y.kana, this.sort_name));
    else if (this.sort_hiring_type)
      employees.sort((x, y) => util.sort_idx(x.hiring_type, y.hiring_type, this.sort_hiring_type));
    else if (this.sort_branch_id)
      employees.sort((x, y) => util.sort_idx(x.branch_id, y.branch_id, this.sort_branch_id));
    else if (this.sort_status)
      employees.sort((x, y) => util.sort_idx(x.status, y.status, this.sort_status));
    else if (this.sort_date)
      employees.sort((x, y) => util.sort_idx(x.retired_on, y.retired_on, this.sort_date));
    else if (this.sort_type)
      employees.sort((x, y) => util.sort_idx(x.type, y.type, this.sort_type));
    else {
      employees.sort((x, y) => util.sort_idx(x.kana, y.kana, 'asc'));
      employees.sort((x, y) => util.sort_idx(x.code, y.code, 'asc'));
      if (this.viewing_branch == 0) {
        employees.sort((x, y) => util.sort_idx(x.branch_id, y.branch_id, 'asc'));
      }
    }
    return employees;
  }
  page_employees(employees) {
    return employees.slice(this.disp_num * (this.page - 1), this.disp_num * this.page);
  }
  sort_click(sort_key) {
    this.page = 1;
    sort_key = 'sort_'+sort_key;
    var sort_mode = undefined;
    if (this[sort_key] === undefined) {
      sort_mode = 'asc';
    } else if (this[sort_key] == 'asc') {
      sort_mode = 'desc';
    } else if (this[sort_key] == 'desc') {
      sort_mode = 'asc';
    }
    this.set_sort(sort_key, sort_mode);
  }
  set_sort(sort_key, sort_mode) {
    console.log(sort_key, sort_mode);
    this.query = {
      employee_sort_date: sort_key == 'sort_date' ? sort_mode: undefined,
      employee_sort_code: sort_key == 'sort_code' ? sort_mode: undefined,
      employee_sort_name: sort_key == 'sort_name' ? sort_mode: undefined,
      employee_sort_hiring_type: sort_key == 'sort_hiring_type' ? sort_mode: undefined,
      employee_sort_branch_id: sort_key == 'sort_branch_id' ? sort_mode: undefined,
      employee_sort_kind: sort_key == 'sort_kind' ? sort_mode: undefined,
      employee_sort_status: sort_key == 'sort_status' ? sort_mode: undefined,
      employee_sort_type: sort_key == 'sort_type' ? sort_mode: undefined,
    };
  }



  get disp_num() {
    return manager.disp_num;
  }
  set disp_num(val) {
    this.page = 1;
    util.setLocalStorage('employee_disp_num', val);
    manager.setDispNum(val);
  }
}