
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import mixinNencho from '@/mixins/mixinNencho';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonSelect from "@/components/common/Select.vue";

@Component({
  components: {
    UtilPopup,
    CommonSelect,
  }
})
export default class Dialog extends Mixins(mixinNencho) {
  reflect() {
    this.m.reflect({nencho_id: this.nencho_id, employee_id: this.m.is_open_reflect_dialog});
  }
  created() {
    this.m.setReflectType(0);
  }


  get reflect_select() {
    return {0: '12月支給給与', 2: '1月支給給与'};
  }

  get reflected_to() {
    return this.m.reflect_type;
  }
  set reflected_to(reflect_type) {
    this.m.setReflectType(reflect_type)
  }

  get is_display() {
    if (this.m.is_open_reflect_dialog === 0) {
      return false;
    } else {
      return true;
    }
  }
  set is_display(val: boolean) {
    if (!val) {
      this.m.setIsOpenReflectDialog(0);
    }
  }
}
