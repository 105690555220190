
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import dialog from "@/vuex/dialog";
import global from "@/vuex/shift/global";
import mixinNencho from '@/mixins/mixinNencho';
import * as util from '@/util';
import UtilPopup from "@/components/util/Popup.vue";
import CommonTab from "@/components/common/Tab.vue";
import CommonSelect from "@/components/common/Select.vue";
import CommonButton from "@/components/common/Button.vue";
import CommonBigbutton from "@/components/common/Bigbutton.vue";

@Component({
  components: {
    UtilPopup,
    CommonTab,
    CommonSelect,
    CommonButton,
    CommonBigbutton,
  }
})
export default class Main extends Mixins(mixinNencho) {
  excel_file = null;
  yayoi_file = {1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null, 8: null, 9: null, 10: null, 11: null, 12: null, 13: null, 14: null, 15: null, 16: null};
  tab = 'normal';

  tab_options = {
    'normal': '独自',
    'yayoi': '弥生',
  };

  change_tab(val) {
    this.tab = val;
  }

  get refs():any {
    return this.$refs;
  }

  get is_display() {
    return this.m.is_open_nencho_excel_dialog;
  }
  set is_display(val: boolean) {
    this.m.setIsOpenNenchoExcelDialog(val);
  }

  select_file(e) {
    e.preventDefault();
    let files = e.target.files;
    this.excel_file = files[0];
    if (this.excel_file) {
      var name = this.excel_file.name.split('.');
      name = name[name.length - 1];
      if (name != 'xlsx') {
        if (name == 'xls') {
          dialog.openErrorAlertDialog({msg: '古いExcel(.xlsファイル)は使用できません。', detail: 'フォームをダウンロードして使用してください。'})
        } else {
          dialog.openErrorAlertDialog({msg: 'Excelファイルのみアップロード可能です。', detail: '.xlsxファイルのみ使用可能です。'})
        }
        this.excel_file = null;
        this.refs.nencho_ref.reset_file();
      }
    }
  }

  select_yayoi_file(e, month) {
    e.preventDefault();
    let files = e.target.files;
    this.yayoi_file[month] = files[0];
    if (this.yayoi_file[month]) {
      var name = this.yayoi_file[month].name.split('.');
      name = name[name.length - 1];
      if (name != 'csv') {
        if (name == 'xlsx' || name == 'xls') {
          dialog.openErrorAlertDialog({msg: 'CSVファイルのみアップロード可能です。', detail: 'Excelで開いて保存した際にExcelファイルに変換されてしまっている可能性があります。<br>再度Excelで開き、「名前をつけて保存」から「CSV (コンマ区切り)」を選択して保存してください。'})
        } else {
          dialog.openErrorAlertDialog({msg: 'CSVファイルのみアップロード可能です。', detail: '.csvファイルのみ使用可能です。'})
        }
        this.yayoi_file[month] = null;
        this.refs['yayoi_ref_' + month].reset_file();
      }
    }
  }

  async update_nencho_excel() {
    if (this.tab == 'normal') {
      await this.m.updateNenchoExcel({excel: this.excel_file, branch_id: this.viewing_branch, nencho_id: this.nencho_id});
      this.excel_file = null;
      this.refs.nencho_ref.reset_file();
    } else if (this.tab == 'yayoi') {
      await this.m.updateNenchoYayoi({
        csv1: this.yayoi_file[1], 
        csv2: this.yayoi_file[2], 
        csv3: this.yayoi_file[3], 
        csv4: this.yayoi_file[4], 
        csv5: this.yayoi_file[5], 
        csv6: this.yayoi_file[6], 
        csv7: this.yayoi_file[7], 
        csv8: this.yayoi_file[8], 
        csv9: this.yayoi_file[9], 
        csv10: this.yayoi_file[10], 
        csv11: this.yayoi_file[11], 
        csv12: this.yayoi_file[12], 
        csv13: this.yayoi_file[13], 
        csv14: this.yayoi_file[14], 
        csv15: this.yayoi_file[15], 
        csv16: this.yayoi_file[16], 
        branch_id: this.viewing_branch, nencho_id: this.nencho_id});
      for (var i = 0; i <= 16; i++) {
        this.yayoi_file[i] = null;
        this.refs['yayoi_ref_' + i].reset_file();
      }
    }
  }
}
