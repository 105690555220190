
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import * as util from "@/util";
import mixinNencho from "@/mixins/mixinNencho";
import global from "@/vuex/nencho/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import NenchoManagerTableControl from "@/components/nencho/manager/table/Control.vue";
import NenchoManagerTableTable from "@/components/nencho/manager/table/Table.vue";
import NenchoManagerPagerFoot from "@/components/nencho/manager/pager/Foot.vue";
import NenchoManagerDialogReflect from "@/components/nencho/manager/dialog/Reflect.vue";
import NenchoManagerDialogReflects from "@/components/nencho/manager/dialog/Reflects.vue";
import NenchoManagerDialogExcel from "@/components/nencho/manager/dialog/Excel.vue";
import NenchoManagerDialogJoinmail from "@/components/nencho/manager/dialog/Joinmail.vue";

@Component({
  components: {
    NenchoManagerTableControl,
    NenchoManagerTableTable,
    NenchoManagerPagerFoot,
    NenchoManagerDialogReflect,
    NenchoManagerDialogReflects,
    NenchoManagerDialogExcel,
    NenchoManagerDialogJoinmail,
  },
})
export default class Main extends Mixins(mixinNencho) {}
