
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import global from "@/vuex/nencho/global";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/nencho/manager";
  import CommonToggle from "@/components/common/Toggle.vue";

  import CommonSelect from "@/components/common/Select.vue";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonToggle,

      CommonSelect,
      CommonButton,
    },
  })
  export default class Row extends Mixins(mixinNencho) {
    @Prop() nencho_master;

    get is_disp_nencho_money() {
      const dateobj = util.today_obj();
      return this.is12Locked;
      return (
        this.is12Locked &&
        (this.nencho.year < Number(dateobj.year) ||
          (this.nencho.year == Number(dateobj.year) && Number(dateobj.month) == 12))
      );
    }

    //乙の人や退職者などが使用
    decide() {
      this.m.decide({
        nencho_id: this.nencho_master.nencho_id,
        employee_id: this.nencho_master.employee_id,
      });
    }
    decide_force() {
      this.m.decide_force({
        nencho_id: this.nencho_master.nencho_id,
        employee_id: this.nencho_master.employee_id,
      });
    }
    undecide() {
      this.m.undecide({
        nencho_id: this.nencho_master.nencho_id,
        employee_id: this.nencho_master.employee_id,
      });
    }
    unreflect() {
      this.m.unreflect({
        nencho_id: this.nencho_master.nencho_id,
        employee_id: this.nencho_master.employee_id,
      });
    }
    decide_force_retire() {
      this.m.decide_force_retire({
        nencho_id: this.nencho_master.nencho_id,
        employee_id: this.nencho_master.employee_id,
      });
    }

    go_to_nencho_employee() {
      this.change_page("nencho_employee", {
        nencho_id: this.nencho_id,
        employee_id: this.nencho_master.employee_id,
        is_finish_employee: undefined,
      });
    }
    go_to_nencho_insurance() {
      this.change_page("nencho_insurance", {
        nencho_id: this.nencho_id,
        employee_id: this.nencho_master.employee_id,
        is_finish_insurance: undefined,
      });
    }
    go_to_nencho_prev_payment() {
      this.change_page("nencho_prev_payment", {
        nencho_id: this.nencho_id,
        employee_id: this.nencho_master.employee_id,
        is_finish_prev_payment: undefined,
      });
    }
    go_to_nencho_loan() {
      this.change_page("nencho_loan", {
        nencho_id: this.nencho_id,
        employee_id: this.nencho_master.employee_id,
        is_finish_loan: undefined,
      });
    }

    get is_valid_nencho() {
      if (this.nencho_master.is_otsu) {
        return false;
      } else {
        return this.nencho_master.is_valid == 1;
      }
    }
    set is_valid_nencho(val) {
      this.m.updateNenchoMasterValid({
        nencho_id: this.nencho_master.nencho_id,
        branch_id: this.viewing_branch,
        employee_id: this.nencho_master.employee_id,
        is_valid: val,
      });
    }

    get completed_nencho_master() {
      return (
        this.nencho_master.option_selected == 1 &&
        this.nencho_master.is_finish_employee == 1 &&
        (this.nencho_master.is_insurance == 0 ||
          this.nencho_master.is_otsu == 1 ||
          this.nencho_master.is_custom_insurance_money == 1 ||
          this.nencho_master.is_finish_insurance == 1) &&
        (this.nencho_master.is_prev_payment == 0 ||
          this.nencho_master.is_otsu == 1 ||
          this.nencho_master.is_custom_prev_payment == 1 ||
          this.nencho_master.is_finish_prev_payment == 1) &&
        (this.nencho_master.is_loan == 0 ||
          this.nencho_master.is_otsu == 1 ||
          this.nencho_master.is_custom_loan_money == 1 ||
          this.nencho_master.is_finish_loan == 1)
      );
    }

    send_reminder() {
      this.m.sendNenchoReminder({
        employee_id: this.nencho_master.employee_id,
        nencho_id: this.nencho_master.nencho_id,
        employee_name: this.employee_hash[this.nencho_master.employee_id].name,
      });
    }

    get tmp_is_special_levy() {
      return this.nencho_master.is_special_levy;
    }
    set tmp_is_special_levy(value) {
      this.m.updateIsSpecialLevy({
        nencho_id: this.nencho_id,
        employee_id: this.nencho_master.employee_id,
        is_special_levy: value,
      });
    }
  }
