
  import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
  import * as util from "@/util";
  import mixinNencho from "@/mixins/mixinNencho";
  import dialog from "@/vuex/dialog";
  import manager from "@/vuex/nencho/manager";
  import CommonButton from "@/components/common/Button.vue";

  @Component({
    components: {
      CommonButton,
    },
  })
  export default class Control extends Mixins(mixinNencho) {
    create_nencho() {
      this.m.createNencho({ payment_info: this.payment_info, bonus_info: this.bonus_info });
    }
  }
