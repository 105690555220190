
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/nencho/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import NenchoManagerTableInputrow from "@/components/nencho/manager/table/Inputrow.vue";
import NenchoManagerTableRow from "@/components/nencho/manager/table/Row.vue";

@Component({
  components: {
    NenchoManagerTableInputrow,
    NenchoManagerTableRow,
  }
})
export default class Table extends Mixins(mixinNencho) {
  get table_column() {
    if (this.is_open) {
      return 18;
    } else {
      return 1;
    }
  }
}
