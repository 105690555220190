
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/nencho/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import CommonText from "@/components/common/Text.vue";

@Component({
  components: {
    CommonText,
  }
})
export default class Row extends Mixins(mixinNencho) {
  @Prop() nencho_master;

  get is_pay_sum_mode() {
    return this.tmp_nencho_master.is_pay_sum_mode;
  }
  set is_pay_sum_mode(val) {
    Vue.set(this.tmp_nencho_master, 'is_pay_sum_mode', val);
  }
  get is_ins_sum_mode() {
    return this.tmp_nencho_master.is_ins_sum_mode;
  }
  set is_ins_sum_mode(val) {
    Vue.set(this.tmp_nencho_master, 'is_ins_sum_mode', val);
  }
  get is_tax_sum_mode() {
    return this.tmp_nencho_master.is_tax_sum_mode;
  }
  set is_tax_sum_mode(val) {
    Vue.set(this.tmp_nencho_master, 'is_tax_sum_mode', val);
  }


  get is_valid_nencho() {
    if (this.nencho_master.is_otsu) {
      return false;
    } else {
      return this.nencho_master.is_valid;
    }
  }


  
  input_pay_payment(val, i) {
    this.is_pay_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'pay_payment_'+i, val);
    this.calc_pay_sum();
  }
  input_pay_bonus(val, i) {
    this.is_pay_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'pay_bonus_'+i, val);
    this.calc_pay_sum();
  }
  input_pay_sum(val) {
    this.is_pay_sum_mode = true;
    Vue.set(this.tmp_nencho_master, 'pay_sum', val);
    this.reset_each_pay();
  }
  calc_pay_sum() {
    var sum = 0;
    for(var i = 1; i <= 12; i++) {
      if (!isNaN(this.tmp_nencho_master['pay_payment_'+i])) {
        sum += Number(this.tmp_nencho_master['pay_payment_'+i]);
      }
    }
    for(var i = 1; i <= 4; i++) {
      if (!isNaN(this.tmp_nencho_master['pay_bonus_'+i])) {
        sum += Number(this.tmp_nencho_master['pay_bonus_'+i]);
      }
    }
    Vue.set(this.tmp_nencho_master, 'pay_sum', sum);
  }
  reset_each_pay() {
    for(var i = 1; i <= 12; i++) {
      Vue.set(this.tmp_nencho_master, 'pay_payment_'+i, null);
    }
    for(var i = 1; i <= 4; i++) {
      Vue.set(this.tmp_nencho_master, 'pay_bonus_'+i, null);
    }
  }

  input_ins_payment(val, i) {
    this.is_ins_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'ins_payment_'+i, val);
    this.calc_ins_sum();
  }
  input_ins_bonus(val, i) {
    this.is_ins_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'ins_bonus_'+i, val);
    this.calc_ins_sum();
  }
  input_ins_sum(val) {
    this.is_ins_sum_mode = true;
    Vue.set(this.tmp_nencho_master, 'ins_sum', val);
    this.reset_each_ins();
  }
  calc_ins_sum() {
    var sum = 0;
    for(var i = 1; i <= 12; i++) {
      if (!isNaN(this.tmp_nencho_master['ins_payment_'+i])) {
        sum += Number(this.tmp_nencho_master['ins_payment_'+i]);
      }
    }
    for(var i = 1; i <= 4; i++) {
      if (!isNaN(this.tmp_nencho_master['ins_bonus_'+i])) {
        sum += Number(this.tmp_nencho_master['ins_bonus_'+i]);
      }
    }
    Vue.set(this.tmp_nencho_master, 'ins_sum', sum);
  }
  reset_each_ins() {
    for(var i = 1; i <= 12; i++) {
      Vue.set(this.tmp_nencho_master, 'ins_payment_'+i, null);
    }
    for(var i = 1; i <= 4; i++) {
      Vue.set(this.tmp_nencho_master, 'ins_bonus_'+i, null);
    }
  }

  input_tax_payment(val, i) {
    this.is_tax_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'tax_payment_'+i, val);
    this.calc_tax_sum();
  }
  input_tax_bonus(val, i) {
    this.is_tax_sum_mode = false;
    Vue.set(this.tmp_nencho_master, 'tax_bonus_'+i, val);
    this.calc_tax_sum();
  }
  input_tax_sum(val) {
    this.is_tax_sum_mode = true;
    Vue.set(this.tmp_nencho_master, 'tax_sum', val);
    this.reset_each_tax();
  }
  calc_tax_sum() {
    var sum = 0;
    for(var i = 1; i <= 12; i++) {
      if (!isNaN(this.tmp_nencho_master['tax_payment_'+i])) {
        sum += Number(this.tmp_nencho_master['tax_payment_'+i]);
      }
    }
    for(var i = 1; i <= 4; i++) {
      if (!isNaN(this.tmp_nencho_master['tax_bonus_'+i])) {
        sum += Number(this.tmp_nencho_master['tax_bonus_'+i]);
      }
    }
    Vue.set(this.tmp_nencho_master, 'tax_sum', sum);
  }
  reset_each_tax() {
    for(var i = 1; i <= 12; i++) {
      Vue.set(this.tmp_nencho_master, 'tax_payment_'+i, null);
    }
    for(var i = 1; i <= 4; i++) {
      Vue.set(this.tmp_nencho_master, 'tax_bonus_'+i, null);
    }
  }





  get tmp_nencho_master() {
    return this.m.nencho_masters[this.nencho_master.employee_id];
  }

  set tmp_nencho_master(val) {
    this.m.setNenchoMaster({employee_id: this.nencho_master.employee_id, nencho_master_object: val});
  }

  get is_edit() {
    return this.m.edit_nencho_masters_flag;
  }
}
