
import { Component, Vue, Prop, Watch, Mixins } from 'vue-property-decorator';
import * as util from '@/util';
import mixinNencho from '@/mixins/mixinNencho';
import global from "@/vuex/nencho/global";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import CommonProgress from "@/components/common/Progress.vue";
import NenchoManagerTableMain from "@/components/nencho/manager/table/Main.vue";

@Component({
  components: {
    CommonProgress,
    NenchoManagerTableMain,
  }
})
export default class Main extends Mixins(mixinNencho) {
}
