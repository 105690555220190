
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import * as util from "@/util";
import mixinNencho from "@/mixins/mixinNencho";
import dialog from "@/vuex/dialog";
import manager from "@/vuex/nencho/manager";
import NenchoManagerTableBranchselector from "@/components/nencho/manager/table/Branchselector.vue";
import NenchoManagerPagerHead from "@/components/nencho/manager/pager/Head.vue";
import CommonButton from "@/components/common/Button.vue";

@Component({
  components: {
    NenchoManagerTableBranchselector,
    NenchoManagerPagerHead,
    CommonButton,
  },
})
export default class Control extends Mixins(mixinNencho) {
  delete_nencho() {
    this.m.deleteNencho({ nencho_id: this.nencho_id });
  }
  edit_nencho_masters() {
    this.is_open = true;

    this.nencho_masters.forEach((nencho_master) => {
      if (nencho_master.reflected_to === null) {
        var nencho_master_object = util.deep_copy(nencho_master);

        if (
          nencho_master.is_custom_pay_sum ||
          nencho_master.is_custom_ins_sum ||
          nencho_master.is_custom_tax_sum
        ) {
          nencho_master_object["is_pay_sum_mode"] = true;
          nencho_master_object["is_ins_sum_mode"] = true;
          nencho_master_object["is_tax_sum_mode"] = true;
          for (var i = 1; i <= 12; i++) {
            nencho_master_object["pay_payment_" + i] = null;
            nencho_master_object["ins_payment_" + i] = null;
            nencho_master_object["tax_payment_" + i] = null;
          }
          for (var i = 1; i <= 4; i++) {
            nencho_master_object["pay_bonus_" + i] = null;
            nencho_master_object["ins_bonus_" + i] = null;
            nencho_master_object["tax_bonus_" + i] = null;
          }
        } else {
          nencho_master_object["is_pay_sum_mode"] = false;
          nencho_master_object["is_ins_sum_mode"] = false;
          nencho_master_object["is_tax_sum_mode"] = false;
        }
        this.m.setNenchoMaster({
          employee_id: nencho_master.employee_id,
          nencho_master_object,
        });
      }
    });

    this.m.editNenchoMasters();
  }
  update_nencho_masters() {
    this.m.updateNenchoMasters({
      nencho_id: this.nencho.id,
      branch_id: this.viewing_branch,
      employee_hash: this.yearly.employee_hash,
    });
  }

  updateNenchoMastersEmployee() {
    this.m.updateNenchoMastersEmployee({
      nencho_id: this.nencho.id,
      branch_id: this.viewing_branch,
      branch_name: this.branch_hash[this.viewing_branch],
    });
  }

  nencho_csv() {
    this.m.nencho_csv({
      branch_id: this.viewing_branch,
      nencho_id: this.nencho.id,
      year: this.nencho.year,
    });
  }
}
